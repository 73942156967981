<template>
  <div>
    <div class="d-flex justify-content-between gap-4 min-h-58">
      <div class="col-md-4 px-0">
        <b-card body-class="d-flex justify-content-between align-items-center flex-row "
          class="pointer card-security mb-2  " variant="primary" button :border-variant="selectedQuestionaire.id === questionaire.id ? 'primary' : 'light'
            " :active="selectedQuestionaire.id === questionaire.id" @click="handleSelectQuestio(questionaire)"
          v-for="(questionaire, index) in items" :key="index">
          <section class="d-flex align-items-center ">
            <span style="top:0" class="ball-selection position-relative"
              :class="selectedQuestionaire.id === questionaire.id && 'selected'" />
            <span> {{ questionaire.name }}</span>
          </section>
          <b-badge variant="primary" pill>
            {{ questionaire.questions.length }} Perguntas
          </b-badge>
        </b-card>
      </div>
      <b-card class="w-100" no-body :header="`Perguntas do questionário ${selectedQuestionaire.name}`">
        <b-list-group flush class="list-questionaire">
          <b-list-group-item v-for="(question, index) in selectedQuestionaire.questions" :key="index">
            {{ question.title }}
          </b-list-group-item>
        </b-list-group>

        <b-card-body>
          <strong>Descrição do questionário</strong>
          <p>{{ selectedQuestionaire.description }}</p>
        </b-card-body>
      </b-card>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <b-button :disabled="!selectedQuestionaire.id" variant="primary" :to="`/homologation/${this.homologationId}/service/${this.serviceId}/edit/document-service/`
        ">
        <span>Avançar para Certidões, Certificações, Alvarás e outros</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { listPaginated } from "@/mixins/listPaginated";
import { mapActions } from "vuex";

export default {
  mixins: [listPaginated],

  data() {
    return {
      serviceId: null,
      homologationId: null,
      action_name: "form",
      selectedQuestionaire: { id: "" },
    };
  },

  methods: {
    ...mapActions(["get_service_scope", "edit_service_scope"]),

    handleSelectQuestio(question) {
      this.selectedQuestionaire = question;
      this.updateService()
    },

    updateService() {
      this.edit_service_scope({
        form: this.selectedQuestionaire.id,
        id: this.serviceId,
      }).then(({ status }) => { });
    },
  },

  mounted() {
    this.homologationId = this.$route.params.homologation_id;
    this.serviceId = this.$route.params.service_id;
    this.get_service_scope({ id: this.serviceId }).then((form) => {
      this.selectedQuestionaire = form.form ?? {
        id: "",
        name: "",
      };
    });
  },
};
</script>

<style lang="scss">
.list-questionaire {
  max-height: 370px;
  overflow: auto;
}
</style>
